import React from "react"
import SEO from "../components/seo"
import Header from "../components/blogHeader"
import Footer from "../components/footer"
import { Link } from "gatsby"

// Styles
import styles from "../styles/pages/success.module.scss"

const ReservationSuccess = props => {
  const details = props.location.state
  return (
    <>
      <Header />
      <SEO title="Reservation Successful" />
      <div className={styles.main}>
        <div className={styles.content}>
          <h1>Reservation Confirmed!</h1>

          {details && details.name && (
            <div className={styles.info}>
              <p>
                Reference Number: <span>{details.reservationId}</span>
              </p>
              <p>Name: {details.name}</p>
              <p>Date: {details.date}</p>
              <p>Time Slot: {details.timeSlot}</p>
            </div>
          )}

          <p className={styles.msg}>
            Please make a note of the above information. If you are unable to
            come, please cancel the reservation so that another person can take
            your spot!
          </p>

          <div className={styles.btnGroup}>
            <Link to="/cancel-reservation">
              <button className={styles.btn2}>Cancel Reservation</button>
            </Link>

            <Link to="/">
              <button className={styles.btn}>Back To Home</button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default ReservationSuccess
